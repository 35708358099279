import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as fomoAnBFebWYIXMeta } from "/opt/buildhome/repo/pages/fomo.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: fomoAnBFebWYIXMeta?.name ?? "fomo",
    path: fomoAnBFebWYIXMeta?.path ?? "/fomo",
    meta: fomoAnBFebWYIXMeta || {},
    alias: fomoAnBFebWYIXMeta?.alias || [],
    redirect: fomoAnBFebWYIXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/fomo.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]