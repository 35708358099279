/**
 * Redirects the user to a specified URL if they were not referred from a given site.
 * @param {string} expectedReferrer - The URL of the site you expect the user to be referred from.
 * @param {string} redirectUrl - The URL to redirect the user to if they were not referred from the expectedReferrer.
 *
 * @see https://www.blackhatworld.com/seo/stop-traffic-from-bouncing-off-your-site-with-this.968350/
 */
export function redirectIfNotReferredFrom(
    expectedReferrer: string,
    redirectUrl: string,
): void {
    const referrer: string = document.referrer;

    if (sessionStorage.getItem('debouncerDone')) {
        // The debouncer has already executed, no action needed.
        console.log('Debouncer already executed. No redirection needed.');
        return;
    }

    // Check if the user was referred from the expectedReferrer
    if (referrer.includes(expectedReferrer) || referrer === expectedReferrer) {
        // Happy path: The user was referred from the expected site, no action needed.
        console.log(
            `User referred from ${expectedReferrer}. No redirection needed.`,
        );
        return;
    }

    if (isLikelyBot()) {
        // The user is likely a bot, no redirection logic needed.
        console.log('User is likely a bot. No redirection needed.');
        return;
    }

    // The user was not referred from the expected site, proceed with redirection logic
    history.replaceState(null, document.title, location.pathname + '#!/auth');
    history.pushState(null, document.title, location.pathname);

    window.addEventListener(
        'popstate',
        () => {
            console.log('Popstate event triggered');
            if (location.hash === '#!/auth') {
                history.replaceState(null, document.title, location.pathname);
                // Redirect the user after a slight delay to ensure the history manipulation is complete
                setTimeout(() => {
                    window.location.replace(redirectUrl);
                    sessionStorage.setItem('debouncerDone', 'yes');
                    console.log(
                        `Debouncer executed: Redirected user to ${redirectUrl} as they were not referred from ${expectedReferrer}.`,
                        {
                            expectedReferrer,
                            redirectUrl,
                            referrer,
                        },
                    );
                }, 0);
            }
        },
        false,
    );
}

// RedirectIfNotReferredFrom.ts

/**
 * Checks if the current visitor is likely a bot based on the user-agent string.
 * @returns {boolean} True if the visitor is likely a bot, false otherwise.
 */
function isLikelyBot(): boolean {
    const userAgent: string = window.navigator.userAgent.toLowerCase();
    // A simple check against a list of common bot user-agent substrings
    const botPatterns: string[] = [
        'googlebot',
        'bingbot',
        'yandexbot',
        'duckduckbot',
        'slurp',
    ];

    return botPatterns.some((botPattern) => userAgent.includes(botPattern));
}
