import { redirectIfNotReferredFrom } from '~/functions/redirectIfNotReferredFrom';

export default defineNuxtPlugin({
    // Doing something with nuxtApp
    parallel: true,
    setup(nuxtApp) {
        console.log('Debouncer plugin loaded');
        redirectIfNotReferredFrom(
            'lv.consulting',
            'https://lv.consulting/fomo',
        );
    },
});
